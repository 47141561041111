/* eslint-disable import/no-anonymous-default-export */
import Api from "./Api";
import { toast } from "react-toast";
export default {

  async refundRazorPay(payload) {
    const response = await Api().post(`api/dashboard/order/refund-razorPay`, payload);
    return response;
  },

  async getOrderRefundDetails(payload) {
    let filter = '';
    if (payload.ord_id) {
      filter += `&fk_ord_id=${payload.ord_id}`;
    }
    if (payload.customer_id) {
      filter += `&fk_customer_id=${payload.customer_id}`;
    }
    if (payload.cust_add_id) {
      filter += `&fk_cust_add_id=${payload.cust_add_id}`;
    }
    const response = await Api().get(`api/dashboard/refund?serach=1${filter}`);
    return response;
  },

  async SaveResetPassword(payload) {
    const response = await Api().post(`api/dashboard/user/reset-password`, payload);
    return response;
  },
  async getUserProfile(payload) {
    const response = await Api().get(`api/dashboard/user/${payload}`);
    return response;
  },

  async getAllUsers(payload) {
    const response = await Api().get(
      `api/dashboard/user/?search=${payload}`
    );
    return response;
  },

  async UserExcelExport(payload) {
    const response = await Api().post(
      `api/dashboard/user/user-excell`,
      payload
    );
    return response;
  },

  async userExcelImport(payload) {
    const response = await Api().post(
      `api/dashboard/user/user-import`,
      payload
    );
    return response;
  },

  async updateUserInfo(payload) {
    const response = await Api().post(
      `api/dashboard/user/update-user-info`,
      payload
    );
    return response;
  },

  async saveMUser(payload) {
    try {
      let {
        user_id,
        user_email: email,
        user_first_name: firstname,
        user_last_name: lastname,
        user_mobile: mobile,
        user_password: password,
        user_role: user_role_id,
        fk_assigned_user_id: fk_assigned_user_id,
      } = payload;
      let body = {
        user_id,
        email,
        firstname,
        lastname,
        mobile: mobile,
        password,
        user_role_id: +user_role_id,
      };

      const res = await Api().post("api/dashboard/user/", payload);
      // console.log("Register Response", res.data, body)
      if (res && res.data.status) {
        toast.success(res.data.message);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("Error in Register Api ", error);
      return null;
    }
  },

  async getAllMUsersList() {
    const response = await Api().get(`api/dashboard/user`);
    return response;
  },

  async getRoleDetails(role_id) {
    const res = await Api().get(`api/dashboard/masters/role/${role_id}`);
    return res;
  },

  // Qc Test Lots API Ends
  async getPermissionsByRoleID(payload) {
    const response = await Api().get(`api/dashboard/masters/role/${payload}`);
    return response;
  },

  async getAllPermissions() {
    const response = await Api().get(`api/dashboard/masters/permission/list`);
    return response;
  },

  async saveRole(payload) {
    const response = await Api().post(
      `api/dashboard/masters/role/auto-save`, //old wala save th end point
      payload
    );
    return response;
  },

  //Fav Start
  async setFavourite(payload) {
    const response = await Api().post(
      `api/dashboard/masters/favourite/`,
      payload
    );
    return response;
  },
  async updateFavourite(payload) {
    console.log("payload", payload);
    const response = await Api().post(
      `api/dashboard/masters/favourite/update`,
      payload
    );
    return response;
  },
  async getUserBasedAllFav() {
    const response = await Api().get(`api/dashboard/masters/favourite/`);
    return response;
  },
  async saveMagazine(payload) {
    const response = await Api().post(`api/dashboard/magazine/`, payload);
    return response;
  },
  async deleteMagazine(payload) {
    const response = await Api().post(
      `api/dashboard/magazine/deleteMagazine/`,
      payload
    );
    return response;
  },
  async getAllMagazine(payload) {
    const response = await Api().get(
      `api/dashboard/magazine/?search=${payload}`
    );
    return response;
  },
  async getSingleMagazine(payload) {
    const response = await Api().get(`api/dashboard/magazine/${payload}`);
    return response;
  },
  async postSkuData(payload) {
    const response = await Api().post(`api/dashboard/skus/`, payload);
    return response;
  },

  async getSkuData(status_code) {
    const response = await Api().get(
      `api/dashboard/skus/?search=${status_code}`
    );
    return response;
  },
  async getCountryBasedSku(payload) {
    const response = await Api().post(
      `api/ord/getcountryBasedskusPrice`, payload
    );
    return response;
  },

  async saveMagazineIssue(payload) {
    const response = await Api().post(
      `api/dashboard/magazine-issues/`,
      payload
    );
    return response;
  },
  async deleteMagazineIssues(payload) {
    const response = await Api().post(
      `api/dashboard/magazine-issues/remove/`,
      payload
    );
    return response;
  },
  async getAllMagazineIssues(payload) {
    const response = await Api().get(
      `api/dashboard/magazine-issues/by-magazine-id/${payload}`
    );
    return response;
  },

  async getAllMagazineIssuesData(payload) {
    const response = await Api().get(
      `api/dashboard/magazine-issues/?search=${payload}`
    );
    return response;
  },

  async getAllProduct(payload) {
    const response = await Api().get(
      `api/dashboard/product/?search=${payload}`
    );
    return response;
  },
  async deleteProd(payload) {
    const response = await Api().post(
      `api/dashboard/product/deleteProd/`,
      payload
    );
    return response;
  },

  async getAllCountry(payload) {
    const response = await Api().get(`api/dashboard/masters/country/?search=${payload}`);
    return response;
  },

  async saveProductData(payload) {
    const response = await Api().post(`api/dashboard/product/`, payload);
    return response;
  },
  async saveSkus(payload) {
    const response = await Api().post(`api/dashboard/skus/`, payload);
    return response;
  },
  /* delete and active inactive both use */
  async removeMaster(payload) {
    const response = await Api().post(
      `api/dashboard/masters/master/remove/`,
      payload
    );
    return response;
  },
  async getSingleSku(payload) {
    const response = await Api().get(`api/dashboard/skus/${payload}`);
    return response;
  },
  async deleteSkus(payload) {
    const response = await Api().post(`api/dashboard/skus/remove/`, payload);
    return response;
  },

  // Order Section Start
  async saveOrder(payload) {
    const response = await Api().post(
      `api/dashboard/order`, //old wala save th end point
      payload
    );
    return response;
  },
  async getOrderList(status_code) {
    let filters = `&filterFrom=&filterTo=&filterOrderId=&filterStatus=&filterOrderStatus=&filterOrderType=`;
    if (status_code?.filterOrder?.filterFlag) {
      let filterOrder = status_code?.filterOrder;
      filters = '';
      if (filterOrder?.filterFrom) {
        filters += `&filterFrom=${filterOrder?.filterFrom}`;
      } else {
        filters += `&filterFrom=`;
      }
      if (filterOrder?.filterTo) {
        filters += `&filterTo=${filterOrder?.filterTo}`;
      } else {
        filters += `&filterTo=`;
      }

      if (filterOrder?.filterOrderId) {
        filters += `&filterOrderId=${filterOrder?.filterOrderId}`;
      } else {
        filters += `&filterOrderId=`;
      }

      if (filterOrder?.filterStatus) {
        filters += `&filterStatus=${filterOrder?.filterStatus}`;
      } else {
        filters += `&filterStatus=`;
      }

      if (filterOrder?.filterOrderType) {
        filters += `&filterOrderType=${filterOrder?.filterOrderType}`;
      } else {
        filters += `&filterOrderType=`;
      }

      if (filterOrder?.filterOrderStatus) {
        filters += `&filterOrderStatus=${filterOrder?.filterOrderStatus}`;
      } else {
        filters += `&filterOrderStatus=`;
      }
    }
    const response = await Api().get(
      `api/dashboard/order/?search=${status_code.status_code}${filters}`
    );
    return response;
    // const response = await Api().get(
    //   `api/dashboard/order/?search=${status_code}`
    // );
    // return response;
  },

  async deleteOrder(payload) {
    const response = await Api().post(`api/dashboard/order/remove/`, payload);
    return response;
  },

  async getSingleOrder(payload) {
    const response = await Api().get(`api/dashboard/order/${payload}`);
    return response;
  },

  async generateInvoiceReport(payload) {
    const response = await Api().post(`api/dashboard/order/generate_invoice`, payload);
    return response;
  },
  async orderExcelImport(payload) {
    const response = await Api().post(
      `api/dashboard/order/order-import`,
      payload
    );
    return response;
  },
  // Order Section End

  async getAllTransactions(status_code) {
    const response = await Api().get(
      `api/dashboard/transaction/?search=${status_code}`
    );
    return response;
  },


  // Subscription Section Start
  async saveSubscriptionInterval(payload) {
    const response = await Api().post(
      `api/dashboard/subscription/subs-interval`, //old wala save th end point
      payload
    );
    return response;
  },
  async updateSubscriptionInterval(payload) {
    const response = await Api().post(
      `api/dashboard/subscription/update-subs-interval`, //old wala save th end point
      payload
    );
    return response;
  },
  async saveSubscription(payload) {
    const response = await Api().post(
      `api/dashboard/subscription`, //old wala save th end point
      payload
    );
    return response;
  },
  async getSubscriptionBasedDispatch(payload) {
    const response = await Api().post(
      `api/dashboard/subs-dispatch/subs-based-dispatch`, //old wala save th end point
      payload
    );
    return response;
  },

  async getAllSubscriptionList(status_code) {
    let filters = `&&filterFrom=&&filterTo=&&filterCustomerName=&&filterCustomerEmail=&&filterCustomerMobile=&&filterSubscriptionId=&&filterOrderId=&&filterMagazineId=&&filterMagazineIssueId=&&filterStatus=`;
    if (status_code?.filterSubscription?.filterFlag) {
      let filterSubscription = status_code?.filterSubscription;
      filters = '';
      if (filterSubscription?.filterFrom) {
        filters += `&&filterFrom=${filterSubscription?.filterFrom}`;
      } else {
        filters += `&&filterFrom=`;
      }
      if (filterSubscription?.filterTo) {
        filters += `&&filterTo=${filterSubscription?.filterTo}`;
      } else {
        filters += `&&filterTo=`;
      }
      if (filterSubscription?.filterCustomerName) {
        filters += `&&filterCustomerName=${filterSubscription?.filterCustomerName}`;
      } else {
        filters += `&&filterCustomerName=`;
      }
      if (filterSubscription?.filterCustomerEmail) {
        filters += `&&filterCustomerEmail=${filterSubscription?.filterCustomerEmail}`;
      } else {
        filters += `&&filterCustomerEmail=`;
      }
      if (filterSubscription?.filterCustomerMobile) {
        filters += `&&filterCustomerMobile=${filterSubscription?.filterCustomerMobile}`;
      } else {
        filters += `&&filterCustomerMobile=`;
      }
      if (filterSubscription?.filterSubscriptionId) {
        filters += `&&filterSubscriptionId=${filterSubscription?.filterSubscriptionId}`;
      } else {
        filters += `&&filterSubscriptionId=`;
      }
      if (filterSubscription?.filterOrderId) {
        filters += `&&filterOrderId=${filterSubscription?.filterOrderId}`;
      } else {
        filters += `&&filterOrderId=`;
      }
      if (filterSubscription?.filterMagazineId?.value) {
        filters += `&&filterMagazineId=${filterSubscription?.filterMagazineId?.value}`;
      } else {
        filters += `&&filterMagazineId=`;
      }
      if (filterSubscription?.filterMagazineIssueId?.value) {
        filters += `&&filterMagazineIssueId=${filterSubscription?.filterMagazineIssueId?.value}`;
      } else {
        filters += `&&filterMagazineIssueId=`;
      }
      if (filterSubscription?.filterStatus) {
        filters += `&&filterStatus=${filterSubscription?.filterStatus}`;
      } else {
        filters += `&&filterStatus=`;
      }
    }
    const response = await Api().get(
      `api/dashboard/subscription/?search=${status_code.status_code}&&customer_id=${status_code?.customer_id ?? 0}${filters}`
      // `api/dashboard/subscription/?search=${status_code.status_code}`
    );
    return response;
  },

  async deleteSubscription(payload) {
    const response = await Api().post(`api/dashboard/subscription/remove/`, payload);
    return response;
  },

  async getSingleSubscription(payload) {
    const response = await Api().get(`api/dashboard/subscription/${payload}`);
    return response;
  },

  async saveSubscriptionDispatch(payload) {
    const response = await Api().post(
      `api/dashboard/subs-dispatch`, //old wala save th end point
      payload
    );
    return response;
  },

  async getAllSubscriptionDispatch(status_code) {
    let filters = `&&filterFrom=&&filterTo=&&filterMonth=&&filterDay=&&filterCustomerName=&&filterCustomerEmail=&&filterCustomerMobile=&&filterSubscriptionId=&&filterOrderId=&&filterMagazineId=&&filterMagazineIssueId=&&filterStatus=&&filterTrackingCode=&&filterTrackingDate=&&filterDispatchStatus=`;

    if (status_code?.filterSubscription?.filterFlag) {
      let filterSubscription = status_code?.filterSubscription;
      filters = '';
      if (filterSubscription?.filterFrom) {
        filters += `&&filterFrom=${filterSubscription?.filterFrom}`;
      } else {
        filters += `&&filterFrom=`;
      }
      if (filterSubscription?.filterTo) {
        filters += `&&filterTo=${filterSubscription?.filterTo}`;
      } else {
        filters += `&&filterTo=`;
      }
      if (filterSubscription?.filterMonth?.value) {
        filters += `&&filterMonth=${filterSubscription?.filterMonth?.value}`;
      } else {
        filters += `&&filterMonth=`;
      }
      if (filterSubscription?.filterDay?.value) {
        filters += `&&filterDay=${filterSubscription?.filterDay?.value}`;
      } else {
        filters += `&&filterDay=`;
      }
      if (filterSubscription?.filterCustomerName) {
        filters += `&&filterCustomerName=${filterSubscription?.filterCustomerName}`;
      } else {
        filters += `&&filterCustomerName=`;
      }
      if (filterSubscription?.filterCustomerEmail) {
        filters += `&&filterCustomerEmail=${filterSubscription?.filterCustomerEmail}`;
      } else {
        filters += `&&filterCustomerEmail=`;
      }
      if (filterSubscription?.filterCustomerMobile) {
        filters += `&&filterCustomerMobile=${filterSubscription?.filterCustomerMobile}`;
      } else {
        filters += `&&filterCustomerMobile=`;
      }
      if (filterSubscription?.filterSubscriptionId) {
        filters += `&&filterSubscriptionId=${filterSubscription?.filterSubscriptionId}`;
      } else {
        filters += `&&filterSubscriptionId=`;
      }
      if (filterSubscription?.filterOrderId) {
        filters += `&&filterOrderId=${filterSubscription?.filterOrderId}`;
      } else {
        filters += `&&filterOrderId=`;
      }
      if (filterSubscription?.filterMagazineId?.value) {
        filters += `&&filterMagazineId=${filterSubscription?.filterMagazineId?.value}`;
      } else {
        filters += `&&filterMagazineId=`;
      }
      if (filterSubscription?.filterMagazineIssueId?.value) {
        filters += `&&filterMagazineIssueId=${filterSubscription?.filterMagazineIssueId?.value}`;
      } else {
        filters += `&&filterMagazineIssueId=`;
      }
      if (filterSubscription?.filterStatus) {
        filters += `&&filterStatus=${filterSubscription?.filterStatus}`;
      } else {
        filters += `&&filterStatus=`;
      }
      if (filterSubscription?.filterTrackingCode) {
        filters += `&&filterTrackingCode=${filterSubscription?.filterTrackingCode}`;
      } else {
        filters += `&&filterTrackingCode=`;
      }

      if (filterSubscription?.filterTrackingDate) {
        filters += `&&filterTrackingDate=${filterSubscription?.filterTrackingDate}`;
      } else {
        filters += `&&filterTrackingDate=`;
      }
      if (filterSubscription?.filterDispatchStatus) {
        filters += `&&filterDispatchStatus=${filterSubscription?.filterDispatchStatus}`;
      } else {
        filters += `&&filterDispatchStatus=`;
      }
    }
    const response = await Api().get(
      `api/dashboard/subs-dispatch/?search=${status_code.status_code}&&customer_id=${status_code?.customer_id ?? 0}${filters}`
      // `api/dashboard/subscription/?search=${status_code.status_code}`
    );
    return response;
  },

  async setSubscriptionDispatchStatus(payload) {
    const response = await Api().post(`api/dashboard/subs-dispatch/status/`, payload);
    return response;
  },
  async deleteSubscriptionDispatch(payload) {
    const response = await Api().post(`api/dashboard/subs-dispatch/remove/`, payload);
    return response;
  },

  async getSingleSubscriptionDispatch(payload) {
    const response = await Api().get(`api/dashboard/subs-dispatch/${payload}`);
    return response;
  },

  async getAllCustomer(payload) {
    const response = await Api().get(`api/dashboard/customer/?search=${payload}`);
    return response;
  },
  async getSingleCustomer(payload) {
    const response = await Api().get(`api/dashboard/customer/${payload}`);
    return response;
  },

  async getAllCustomerAddress(payload) {
    const response = await Api().get(`api/dashboard/customer-add/?search=${payload?.status_code}&&customer_id=${payload?.customer_id ?? 0}`);
    return response;
  },
  async getSingleCustomerAddress(payload) {
    const response = await Api().get(`api/dashboard/customer-add/${payload}`);
    return response;
  },
  async saveCustomerAddress(payload) {
    const response = await Api().post(`api/dashboard/customer-add/`, payload);
    return response;
  },
  async saveCustomer(payload) {
    const response = await Api().post(`api/dashboard/customer/`, payload);
    return response;
  },
  async deleteCustomer(payload) {
    const response = await Api().post(`api/dashboard/customer/remove/`, payload);
    return response;
  },
  async deleteCustomerAdd(payload) {
    const response = await Api().post(`api/dashboard/customer-add/remove/`, payload);
    return response;
  },
  // Subscription Section End

  // Vendor Section Start
  async getAllVendor(payload) {
    const response = await Api().get(`api/dashboard/vendor/?search=${payload}`);
    return response;
  },
  async getSingleVendor(payload) {
    const response = await Api().get(`api/dashboard/vendor/${payload}`);
    return response;
  },
  async saveVendor(payload) {
    const response = await Api().post(`api/dashboard/vendor/`, payload);
    return response;
  },
  async activeVendor(payload) {
    const response = await Api().post(`api/dashboard/vendor/status/`, payload);
    return response;
  },
  async deleteVendor(payload) {
    const response = await Api().post(`api/dashboard/vendor/remove/`, payload);
    return response;
  },
  // Vendor Section End

  // Vendor Transaction Section Start
  async getAllVendorTransaction(status_code) {
    let filters = `&&filterFrom=&&filterTo=&&filterVendorName=&&filterVendorEmail=&&filterVendorMobile=&&filterMagazineId=&&filterMagazineIssueId=&&filterStatus=`;

    if (status_code?.filterVendorTransaction?.filterFlag) {
      let filterVendorTransaction = status_code?.filterVendorTransaction;
      filters = '';
      if (filterVendorTransaction?.filterFrom) {
        filters += `&&filterFrom=${filterVendorTransaction?.filterFrom}`;
      } else {
        filters += `&&filterFrom=`;
      }
      if (filterVendorTransaction?.filterTo) {
        filters += `&&filterTo=${filterVendorTransaction?.filterTo}`;
      } else {
        filters += `&&filterTo=`;
      }
      if (filterVendorTransaction?.filterVendorName) {
        filters += `&&filterVendorName=${filterVendorTransaction?.filterVendorName}`;
      } else {
        filters += `&&filterVendorName=`;
      }
      if (filterVendorTransaction?.filterVendorEmail) {
        filters += `&&filterVendorEmail=${filterVendorTransaction?.filterVendorEmail}`;
      } else {
        filters += `&&filterVendorEmail=`;
      }
      if (filterVendorTransaction?.filterVendorMobile) {
        filters += `&&filterVendorMobile=${filterVendorTransaction?.filterVendorMobile}`;
      } else {
        filters += `&&filterVendorMobile=`;
      }

      if (filterVendorTransaction?.filterStatus) {
        filters += `&&filterStatus=${filterVendorTransaction?.filterStatus}`;
      } else {
        filters += `&&filterStatus=`;
      }

      if (filterVendorTransaction?.filterMagazineId?.value) {
        filters += `&&filterMagazineId=${filterVendorTransaction?.filterMagazineId?.value}`;
      } else {
        filters += `&&filterMagazineId=`;
      }
      if (filterVendorTransaction?.filterMagazineIssueId?.value) {
        filters += `&&filterMagazineIssueId=${filterVendorTransaction?.filterMagazineIssueId?.value}`;
      } else {
        filters += `&&filterMagazineIssueId=`;
      }
    }
    const response = await Api().get(`api/dashboard/vendor-transaction/?search=${status_code.status_code}${filters}`);
    return response;
  },
  async getAllVendorIssuesTransaction(payload) {
    const response = await Api().get(`api/dashboard/vendor-transaction/vendor-trans/trans/allTrans/?search=${payload.search}&&fk_ven_iss_trax_id=${payload.fk_ven_iss_trax_id}`);
    return response;
  },
  async getSingleVendorTransaction(payload) {
    const response = await Api().get(`api/dashboard/vendor-transaction/${payload}`);
    return response;
  },
  async saveVendorTransaction(payload) {
    const response = await Api().post(`api/dashboard/vendor-transaction/`, payload);
    return response;
  },
  async activeVendorTransaction(payload) {
    const response = await Api().post(`api/dashboard/vendor-transaction/status/`, payload);
    return response;
  },
  async deleteVendorTransaction(payload) {
    const response = await Api().post(`api/dashboard/vendor-transaction/remove/`, payload);
    return response;
  },
  // Vendor Transaction Section End

  // Voucher Section Start
  async saveVoucher(payload) {
    const response = await Api().post(
      `api/dashboard/voucher`, //old wala save th end point
      payload
    );
    return response;
  },
  async getVoucherList(status_code) {
    const response = await Api().get(
      `api/dashboard/voucher/?search=${status_code}`
    );
    return response;
  },
  async getAllCampignVoucher(status_code) {
    const response = await Api().get(
      `api/dashboard/voucher/campign/${status_code?.voucher_id}/?search=${status_code?.status_code}`
    );
    return response;
  },


  async deleteVoucher(payload) {
    const response = await Api().post(`api/dashboard/voucher/remove/`, payload);
    return response;
  },

  async getSingleVoucher(payload) {
    const response = await Api().get(`api/dashboard/voucher/${payload}`);
    return response;
  },

  async getStateByCountry(payload) {
    const response = await Api().get(`api/dashboard/masters/state/${payload}`);
    return response;
  },
  async getCityByState(payload) {
    const response = await Api().get(`api/dashboard/masters/city/${payload}`);
    return response;
  },
  async getAllCity() {
    const response = await Api().get(`api/dashboard/masters/city`);
    return response;
  },
  // Voucher Section End


  async getAllRole(payload) {
    const response = await Api().get(
      `api/dashboard/masters/role/type/rolelist/?search=${payload}`
    );
    return response;
  },
  async getAllCommission(status_code) {
    let filters = `&&filterFrom=&&filterTo=&&filterUserName=&&filterUserEmail=&&filterUserMobile=&&filterOrderId=&&filterRoleId=&&filterCommissionStatus=`;

    if (status_code?.filterCommission?.filterFlag) {
      let filterCommission = status_code?.filterCommission;
      filters = '';
      if (filterCommission?.filterFrom) {
        filters += `&&filterFrom=${filterCommission?.filterFrom}`;
      } else {
        filters += `&&filterFrom=`;
      }
      if (filterCommission?.filterTo) {
        filters += `&&filterTo=${filterCommission?.filterTo}`;
      } else {
        filters += `&&filterTo=`;
      }
      if (filterCommission?.filterUserName) {
        filters += `&&filterUserName=${filterCommission?.filterUserName}`;
      } else {
        filters += `&&filterUserName=`;
      }
      if (filterCommission?.filterUserEmail) {
        filters += `&&filterUserEmail=${filterCommission?.filterUserEmail}`;
      } else {
        filters += `&&filterUserEmail=`;
      }
      if (filterCommission?.filterUserMobile) {
        filters += `&&filterUserMobile=${filterCommission?.filterUserMobile}`;
      } else {
        filters += `&&filterUserMobile=`;
      }
      if (filterCommission?.filterOrderId) {
        filters += `&&filterOrderId=${filterCommission?.filterOrderId}`;
      } else {
        filters += `&&filterOrderId=`;
      }
      if (filterCommission?.filterRoleId?.value) {
        filters += `&&filterRoleId=${filterCommission?.filterRoleId?.value}`;
      } else {
        filters += `&&filterRoleId=`;
      }

      if (filterCommission?.filterCommissionStatus) {
        filters += `&&filterCommissionStatus=${filterCommission?.filterCommissionStatus}`;
      } else {
        filters += `&&filterCommissionStatus=`;
      }
    }
    const response = await Api().get(
      `api/dashboard/commission/?search=${status_code.status_code}&&fk_user_id=${status_code?.fk_user_id ?? 0}${filters}`
    );
    return response;
  },

  async getAllPrintSubsIssuesList(status_code) {
    let filters = `&&filterMagazineId=&&filterMagazineIssueId=&&filterFinalize=`;
    if (status_code?.filterPrintSubsIssues?.filterFlag) {
      let filterPrintSubsIssues = status_code?.filterPrintSubsIssues;
      filters = '';

      if (filterPrintSubsIssues?.filterMagazineId?.value) {
        filters += `&&filterMagazineId=${filterPrintSubsIssues?.filterMagazineId?.value}`;
      } else {
        filters += `&&filterMagazineId=`;
      }
      if (filterPrintSubsIssues?.filterMagazineIssueId?.value) {
        filters += `&&filterMagazineIssueId=${filterPrintSubsIssues?.filterMagazineIssueId?.value}`;
      } else {
        filters += `&&filterMagazineIssueId=`;
      }
      if (filterPrintSubsIssues?.filterFinalize) {
        filters += `&&filterFinalize=${filterPrintSubsIssues?.filterFinalize}`;
      } else {
        filters += `&&filterFinalize=`;
      }

    }
    const response = await Api().get(
      `api/dashboard/print-issue/?search=${status_code.status_code}${filters}`
    );
    return response;
  },
  async savePrintSubsIssues(payload) {
    const response = await Api().post(
      `api/dashboard/print-issue`, //old wala save th end point
      payload
    );
    return response;
  },
};
